<template>
  <ion-item color="light" button @click="downloadImage()" detail="false"
    >Download "No Soliciting Sign"</ion-item
  >
</template>

<script>
// import photo from "../images/KS_NO_SOLICITING_SIGN.jpg";
import { IonItem } from "@ionic/vue";
import { defineComponent } from "vue";
// import noSolicitingSign from "../images/No_Soliciting_Sign.pdf";

export default defineComponent({
  components: { IonItem },
  setup() {
    async function downloadImage() {
      const url = "/No_Soliciting_Sign.pdf"; // Path to the PDF in your app

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        console.log("response ok");
        const blob = await response.blob();
        const urlBlob = URL.createObjectURL(blob);
        // const urlBlob = URL.createObjectURL(noSolicitingSign);
        const link = document.createElement("a");
        link.href = urlBlob;
        link.download = "No_Soliciting_Sign.pdf";
        link.click();
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    }

    return { downloadImage };
  },
});
</script>
