<template>
  <modal-base>
    <template v-slot:modalContent v-if="contractor">
      <contractor-display :contractorData="contractor"></contractor-display>
      <appointment-info :infoType="appointment.type"></appointment-info>
      <ion-list v-if="appointmentValues">
        <ion-item color="light">
          <ion-label> {{ appointment.appType }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label> When: </ion-label>
          <ion-button color="light" :disabled="true">
            {{ appointment.date.dateFormatted }} | {{ appointment.appTime }}
          </ion-button>
        </ion-item>

        <ion-item>
          <ion-label>Where:</ion-label>
          <ion-button color="light" :disabled="true">
            {{ appointment.address }}
          </ion-button>
        </ion-item>

        <ion-item>
          <ion-label> Phone Number: </ion-label>
          <ion-button
            @click="callPhoneNumber(contractor.phoneNumber)"
            color="light"
            >{{ contractor.phoneNumber }}</ion-button
          >
        </ion-item>
      </ion-list>
    </template>
    <template v-slot:reviewFooter>
      <div style="display: flex">
        <ion-button
          expand="block"
          color="light"
          @click="presentAlert()"
          style="width: 50%"
          >Cancel Appointment
        </ion-button>
        <ion-button
          expand="block"
          color="light"
          @click="openModal(AppointmentModal)"
          style="width: 50%"
          >Reschedule</ion-button
        >
      </div>
    </template>
  </modal-base>
</template>

<script>
// import { doc, updateDoc } from "firebase/firestore";
// import { auth, db } from "../firebase/config";

// import VueCal from "vue-cal";
// import "vue-cal/dist/vuecal.css";
// import { profanityFilter } from "../composables/profanityFilter.js";

import {
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  modalController,
  alertController,
} from "@ionic/vue";
import {
  defineComponent,
  ref,
  onMounted,
  // watch
} from "vue";

import ContractorDisplay from "../components/ContractorDisplay.vue";
import AppointmentModal from "../components/AppointmentModal.vue";
import { findDistance } from "../composables/findDistance.js";
import { typAsync } from "../composables/typAsync.js";
import AppointmentInfo from "../components/AppointmentInfo.vue";
import ModalBase from "../components/ModalBase.vue";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
// import ScheduleModal from "../components/ScheduleModal.vue";
import { Browser } from "@capacitor/browser";
// import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";

export default defineComponent({
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  components: {
    AppointmentInfo,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    ModalBase,
    ContractorDisplay,
  },
  setup(props) {
    const appointmentValues = ref(props.appointment);
    const userData = ref(props.userData);
    const contractor = ref("");
    // const userData = ref(props.userData)
    const assign = ref(props.appointment.employee);
    const employeeValues = ref({});

    onMounted(() => {
      return typAsync(async () => {
        contractor.value = await getContractorData();
      });
    });

    async function getContractorData() {
      let docRef = doc(db, "contractors", appointmentValues.value.contractorId);
      const docSnap = await getDoc(docRef);

      if (docSnap && docSnap.exists()) {
        // console.log("docSnap", docSnap.data());
        let contractorLat = docSnap.data().geodata.lat;
        let contractorLng = docSnap.data().geodata.lng;
        let multiplier = docSnap.data().multiplier;
        let radius = docSnap.data().radius;
        // console.log("docSnap.data()", docSnap.data());
        let d = await findDistance(
          userData.value.geodata.lat,
          userData.value.geodata.lng,
          contractorLat,
          contractorLng
        );
        // console.log("d", d);

        if (multiplier > 1 && d > radius) {
          multiplier = 1;
        }

        let contractorValues = {
          ...docSnap.data(),
          distance: d,
          multiplier: multiplier,
        };

        return contractorValues;
      } else {
        console.error(
          "Searched for contractor and document not found in database"
        );
      }
    }

    async function presentAlert() {
      const alert = await alertController.create({
        header: "Are you sure you'd like to cancel this appointment?",
        buttons: [
          {
            text: "No",
            role: "cancel",
          },
          {
            text: "Yes",
            role: "confirm",
          },
        ],
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();
      if (role === "confirm") {
        await cancelAppointment();
      }
    }

    async function cancelAppointment() {
      return typAsync(async () => {
        // await deleteDoc(doc(db, "appointments", appointmentValues.value.id));
        await updateDoc(doc(db, "appointments", appointmentValues.value.id), {
          status: "canceled",
        });
        location.reload();
      });
    }

    function callPhoneNumber(phoneNumber) {
      return typAsync(async () => {
        const url = `tel:${phoneNumber}`;
        await Browser.open({ url });
      });
    }

    async function openModal(modalType) {
      // console.log("tried to open modal: ", modalType);
      const modal = await modalController.create({
        component: modalType,

        componentProps: {
          userData: userData.value,
          contractorData: contractor.value,
          appointment: appointmentValues.value,
        },
      });
      modal.present();
      await modal.onWillDismiss();
    }

    return {
      assign,
      employeeValues,
      appointmentValues,
      presentAlert,
      openModal,
      contractor,
      AppointmentModal,
      callPhoneNumber,
    };
  },
});
</script>

<style scoped></style>
