<!-- this base layout will be used on every page so it is a initalized as a global component in main.js -->

<template>
  <!-- <div scroll-y="true"> -->
  <ion-header>
    <ion-toolbar class="toolbar-base-layout">
      <ion-buttons slot="start" style="flex-basis: 25%">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>

      <div
        style="
          width: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
        "
      >
        <ion-img
          :src="photoOne"
          slot="center"
          class="ks-img-base-layout"
          style="height: 45px; cursor: pointer"
          @click="goToThis('home')"
        ></ion-img>
      </div>
      <nav-bar slot="end" style="flex-basis: 25%"></nav-bar>
    </ion-toolbar>
  </ion-header>

  <!--  Page content -->

  <ion-content v-if="backgroundKSImage" id="content-base-layout">
    <div class="w-full flex items-center justify-center z-10">
      <div class="w-[800px] min-w-[350px] max-w-[800px]">
        <slot />
      </div>
    </div>
  </ion-content>
  <ion-content v-else>
    <div class="w-full flex items-center justify-center z-10">
      <div class="w-[800px] min-w-[350px] max-w-[800px]">
        <slot />
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonImg,
} from "@ionic/vue";

import NavBar from "@/components/NavBar.vue";
import photoOne from "../images/KS_LOGO_WHITE_200x95.png";
// import AccountAcord from "@/components/AccountAcord.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
// import getUser from "@/composables/getUser";

export default {
  // props: ["pageTitle", "pageDefaultBackLink"],
  components: {
    IonHeader,
    IonContent,
    IonToolbar,
    IonButtons,
    NavBar,
    IonMenuButton,
    IonImg,
  },
  props: {
    backgroundKSImage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const userData = ref();
    const router = useRouter();
    // const { user } = getUser();

    function goToThis(pageName) {
      router.push(`/${pageName}`);
    }

    return {
      goToThis,
      userData,
      photoOne,
      // KSContractors,
    };
  },
};
</script>

<style scoped>
.toolbar-base-layout {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.ks-img-base-layout:hover {
  cursor: pointer;
  opacity: 0.8;
}

#content-base-layout {
  --background: transparent;
  /* --ion-background-color: transparent; */
}
</style>
