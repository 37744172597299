import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase/config";

export async function getUserData() {
  let userData;

  async function getUser() {
    const user = auth.currentUser;

    if (user) {
      const docRef = doc(db, "accounts", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        userData = docSnap.data();
      } else {
        console.error("Error: getUserData does not exist");
      }
    } else {
      console.error("No user is currently signed in.");
    }
  }

  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await getUser();
      }
      resolve();
    });

    // Clean up the listener
    return () => unsubscribe();
  });

  // console.log("getuserdata tried: ", auth);
  return userData;
}
