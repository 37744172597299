<template>
  <modal-base>
    <template v-slot:modalContent>
      <contractor-display :contractorData="contractorData"></contractor-display>
      <div v-if="showModal" style="padding-bottom: 50px">
        <ion-list>
          <ion-item color="light">
            <ion-label>Select Appointment Type</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Select Type:</ion-label>
            <ion-select v-model="type" placeholder="select type">
              <ion-select-option value="inspection"
                >Storm Damage Inspection</ion-select-option
              >
              <ion-select-option value="estimate"
                >Replacement Estimate</ion-select-option
              >
              <ion-select-option value="adjustment"
                >Insurace Adjustment</ion-select-option
              >
            </ion-select>
          </ion-item>
          <appointment-info v-if="type" :infoType="type"></appointment-info>
          <ion-item color="light">
            <ion-label>Select Appointment Date</ion-label>
          </ion-item>

          <ion-item v-if="offseason">
            <p>
              Contractor is in their offseason, appointments will begin two
              weeks before {{ offseason }}
            </p>
          </ion-item>
          <div v-else>
            <calendar-popover
              :options="options"
              @date-selected="dateSelected"
            ></calendar-popover>
          </div>
          <ion-item color="light">
            <ion-label> Select Time</ion-label>
          </ion-item>

          <ion-item v-if="availableHours.length">
            <ion-label>Select Time:</ion-label>
            <ion-select v-model="time" placeholder="select time">
              <ion-select-option
                v-for="hour in availableHours"
                :key="hour"
                :value="hour.time"
                >{{ hour.formatted }}</ion-select-option
              >
            </ion-select>
          </ion-item>

          <div v-else>
            <ion-item> Select a new date </ion-item>
          </div>

          <ion-item color="light">
            <ion-label> Enter Contact Information </ion-label>
          </ion-item>

          <ion-item>
            <ion-label>Phone Number: </ion-label>

            <ion-input
              @ionInput="formatPhoneNumber($event)"
              placeholder="enter phone number"
            ></ion-input>
          </ion-item>
        </ion-list>
      </div>
      <div v-else>
        <ion-item>
          Can not schedule an appointment since {{ contractorData.name }} has
          selected that appointments be within {{ radius }} miles of
          {{ contractorData.formatedAddress }}.
        </ion-item>
      </div>
    </template>
    <template v-slot:reviewFooter>
      <ion-button
        v-if="type && time && appointmentDate && userPhoneNumber"
        expand="block"
        @click="submit()"
        >Submit Appointment</ion-button
      >
      <ion-button v-else expand="block" :disabled="true"
        >Submit Appointment</ion-button
      >
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import AppointmentInfo from "../components/AppointmentInfo.vue";

// import VueCal from "vue-cal";
// import "vue-cal/dist/vuecal.css";
// import SliderComponent from "../components/SliderComponent.vue";
import CalendarPopover from "../components/CalendarPopover.vue";
// import InfoPopover from "../components/InfoPopover.vue";
// import { trash } from "ionicons/icons";

import {
  IonItem,
  // IonPage,
  IonLabel,
  IonButton,
  IonList,
  // IonAccordion,
  // IonAccordionGroup,
  IonSelect,
  IonSelectOption,
  // loadingController,
  IonInput,
} from "@ionic/vue";
import { defineComponent, onMounted, ref } from "vue";
// import { getUserData } from "../composables/getUserData.js";

import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { formatTime } from "../composables/formatTime.js";
import { typAsync } from "../composables/typAsync.js";
import ContractorDisplay from "../components/ContractorDisplay.vue";
export default defineComponent({
  components: {
    ModalBase,
    IonItem,
    ContractorDisplay,
    IonLabel,
    IonButton,
    IonList,
    CalendarPopover,
    IonSelect,
    IonSelectOption,
    AppointmentInfo,
    IonInput,
    // VueCal,
  },
  props: {
    contractorData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: false,
    },
  },

  setup(props) {
    const contractorValues = ref(props.contractorData);
    const userValues = ref(props.userData);

    const contractorId = contractorValues.value.contractorId;
    const userId = userValues.value.userId;
    const userPhoneNumber = ref("");
    let dateObject = null;
    let contractorPreferences = {};
    let appointments = {};
    const options = ref({
      title: "Appointment Date:",
      hideWeekdays: "",
      time: false,
      transitions: false,
      activeView: "month",
      disableViews: ["week", "day", "year", "years"],
      style: "width: 180px; height: auto",
      disableDays: "",
      minDate: "",
      maxDate: "",
      trig: "appointment-date",
    });
    const showModal = ref(true);
    const appointmentDate = ref("");
    const offseason = ref("");
    let hours = {};

    const availableHours = ref([]);
    const type = ref("");
    const time = ref("");
    const radius = ref("");

    onMounted(async () => {
      return typAsync(async () => {
        contractorPreferences = await getContractorPreferences(
          "00TwcnghyCqvsCIHCMbl"
        );

        let withinRadius = await checkDistance(contractorPreferences.radius);
        if (withinRadius) {
          await getNewDates();
          await setContractorPreferences(contractorPreferences);
        } else {
          showModal.value = false;
        }
      });
    });

    async function checkDistance(rad) {
      let distance = Number(contractorValues.value.distance);
      if (rad < distance) {
        radius.value = rad;
        return false;
      } else {
        return true;
      }
    }

    async function getNewDates() {
      return new Promise((resolve) => {
        let currentDate = new Date();
        let nextDay = new Date();
        let twoDays = new Date();
        let threeDays = new Date();
        let twoWeeks = new Date();

        nextDay.setDate(currentDate.getDate() + 1);
        twoDays.setDate(currentDate.getDate() + 2);
        threeDays.setDate(currentDate.getDate() + 3);
        twoWeeks.setDate(currentDate.getDate() + 14);

        dateObject = {
          currentDate: currentDate.format(),
          nextDay: nextDay.format(),
          twoDays: twoDays.format(),
          threeDays: threeDays.format(),
          twoWeeks: twoWeeks.format(),
        };
        resolve();
      });
    }

    async function getContractorPreferences() {
      const docRef = doc(db, "preferences", contractorId);
      const docSnap = await getDoc(docRef);
      if (docSnap.data()) {
        appointments = docSnap.data().appointments;
        return docSnap.data().appointmentPreferences;
      } else {
        console.error("error: could not find contractor data");
      }
    }

    function formatPhoneNumber(eventValue) {
      const phoneNumber = eventValue.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
      const formattedPhoneNumber = phoneNumber
        .slice(0, 10)
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // Format the phone number
      eventValue.target.value = formattedPhoneNumber; // Update the input value

      if (formattedPhoneNumber.split("-").length === 3) {
        userPhoneNumber.value = formattedPhoneNumber;
      } else {
        userPhoneNumber.value = "";
      }
    }

    async function setContractorPreferences(pref) {
      // console.log("preferences: ", pref);
      return new Promise((resolve) => {
        let hideWeekdays = [];
        let disableDays = [];
        let minDate = "";
        let maxDate = "";

        // set days
        Object.keys(pref.availableDays).forEach((day) => {
          if (pref.availableDays[day] === false) {
            if (day === "monday") {
              hideWeekdays.push(1);
            } else if (day === "tuesday") {
              hideWeekdays.push(2);
            } else if (day === "wednesday") {
              hideWeekdays.push(3);
            } else if (day === "thursday") {
              hideWeekdays.push(4);
            } else if (day === "friday") {
              hideWeekdays.push(5);
            } else if (day === "saturday") {
              hideWeekdays.push(6);
            } else if (day === "sunday") {
              hideWeekdays.push(7);
            }
          }
        });

        // set disabled days from blocked days
        Object.keys(pref.blockedDays).forEach((day) => {
          disableDays.push(`${day}`);
        });

        // set minimum date
        let minimumNumber;
        let dIA = pref.daysInAdvance;
        let tempDIA = "";
        let tempOffseasonEnd = 100000000;

        if (pref.offseason.end.disableDate) {
          tempOffseasonEnd = Number(
            pref.offseason.end.disableDate.replace(/-/g, "")
          );
        }

        if (dIA === "next") {
          tempDIA = Number(dateObject.nextDay.replace(/-/g, ""));
          dIA = dateObject.nextDay;
        } else if (dIA === "two") {
          tempDIA = Number(dateObject.twoDays.replace(/-/g, ""));
          dIA = dateObject.twoDays;
        } else if (dIA === "three") {
          tempDIA = Number(dateObject.threeDays.replace(/-/g, ""));
          dIA = dateObject.threeDays;
        }

        if (tempDIA < tempOffseasonEnd) {
          minDate = dIA;
          minimumNumber = tempDIA;
        } else {
          minDate = pref.offseason.end.disableDate;
          minimumNumber = tempOffseasonEnd;
        }

        // set maximum date
        let maximumNumber;
        let tempOffseasonStart = 100000000;
        if (pref.offseason.start.disableDate) {
          tempOffseasonStart = Number(
            pref.offseason.start.disableDate.replace(/-/g, "")
          );
        }

        let tempTwoWeeks = Number(dateObject.twoWeeks.replace(/-/g, ""));

        if (tempTwoWeeks < tempOffseasonStart) {
          maxDate = dateObject.twoWeeks;
          maximumNumber = tempTwoWeeks;
        } else {
          maxDate = pref.offseason.start.disableDate;
          maximumNumber = tempOffseasonStart;
        }

        if (minimumNumber > maximumNumber) {
          offseason.value = pref.offseason.end.dateFormatted;
        }

        // hours per day
        hours = {
          start: pref.hours.start,
          end: pref.hours.end,
          total: pref.hours.end - pref.hours.start,
        };

        // options for calendar popover

        options.value = {
          title: "Appointment Date:",
          hideWeekdays: hideWeekdays,
          time: false,
          transitions: false,
          activeView: "month",
          disableViews: ["week", "day", "year", "years"],
          style: "width: 180px; height: auto",
          disableDays: disableDays,
          minDate: minDate,
          maxDate: maxDate,
          trig: "appointment-date",
        };
        // console.log("option values:", options.value);

        //
        resolve();
      });
    }

    function dateSelected(dateSelected) {
      appointmentDate.value = dateSelected;
      // console.log("appointment date: ", appointmentDate);
      availableHours.value = [];
      time.value = "";

      let bookedHours = "";
      if (appointments[dateSelected.disableDate]) {
        bookedHours = appointments[dateSelected.disableDate];
      }

      let maxPerHour = contractorPreferences.perHour;

      for (let i = hours.start; i < hours.end; i++) {
        let counter = maxPerHour; //3
        if (bookedHours) {
          for (let j = 0; j < bookedHours.length; j++) {
            if (bookedHours[j] === i) {
              counter--;
            }
          }
        }

        if (counter > 0) {
          let formattedTime = formatTime(i);

          availableHours.value.push({
            time: i,
            formatted: formattedTime,
          });
        }
      }
    }

    async function submit() {
      return typAsync(async () => {
        let appointmentObject = {
          time: time.value,
          type: type.value,
          name: userValues.value.name,
          userId: userId,
          contractorId: contractorId,
          phone: userPhoneNumber.value,
          address: userValues.value.formattedAddress,
          contractor: contractorValues.value.name,
          date: appointmentDate.value,
          employee: contractorId,
        };

        let arrayToAdd = [];

        let disableDate = appointmentDate.value.disableDate;

        if (appointments[disableDate]) {
          arrayToAdd = [...appointments[disableDate], time.value];
        } else {
          arrayToAdd = [time.value];
        }
        appointments[disableDate] = arrayToAdd;

        // console.log(
        //   "appointmentObject: ",
        //   appointmentObject,
        //   "appBlock: ",
        //   appointments
        // );

        if (props.appointment) {
          // console.log("tried to set doc: ", props.appointment);
          await setDoc(doc(db, "appointments", props.appointment.id), {
            ...appointmentObject,
            status: "rescheduled",
          });
        } else {
          await addDoc(collection(db, "appointments"), {
            ...appointmentObject,
            status: "active",
          });
        }

        await updateDoc(doc(db, "preferences", contractorId), {
          appointments: appointments,
        });

        location.reload();
      });
      // location.href = "https://example.com"; use this to point back to a page after reload
      // reload the page to refresh contractors so appointments cant duplicate and appointmetn will show in account accord
    }

    return {
      options,
      offseason,
      dateSelected,
      availableHours,
      type,
      time,
      showModal,
      radius,
      appointmentDate,
      submit,
      userPhoneNumber,
      formatPhoneNumber,
    };
  },
});
</script>

<style scoped></style>
