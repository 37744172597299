export function formatTime(timeValue) {
  let formattedTime = "";
  if (timeValue > 12) {
    if (timeValue === 24) {
      formattedTime = `12:00 AM`;
    } else {
      formattedTime = `${timeValue - 12}:00 PM`;
    }
  } else {
    if (timeValue === 12) {
      formattedTime = `12:00 PM`;
    } else {
      formattedTime = `${timeValue}:00 AM`;
    }
  }
  return formattedTime;
}
