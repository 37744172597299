<template>
  <div class="content-appointment-info" v-if="infoTypeValue">
    <div style="display: flex; justify-content: space-between">
      <p style="padding-bottom: 0; margin-bottom: 0">
        During the appointment, the contractor will:
      </p>
      <!-- <p style="padding-bottom: 0; margin-bottom: 0">What to expect:</p> -->
      <ion-icon
        :src="closeCircle"
        style="
          width: 20px;
          height: 20px;
          color: grey;
          padding-bottom: 0;
          margin-bottom: 0;
          margin-right: 10px;
          margin-top: 10px;
        "
        @click="closeInfo()"
      >
      </ion-icon>
    </div>
    <div>
      <ul style="padding-left: 10px; margin-left: 10px">
        <div v-if="infoType === 'inspection'">
          <li>
            Inspect the roof, siding, and gutters for any signs of damage.
          </li>
          <li>
            Look for any internal leaking or water spots that may indicate storm
            damage.
          </li>
          <li>Provide you with a detailed report of their findings.</li>
          <li>
            Please make sure that the contractor has access to all areas of your
            property that need to be inspected.
          </li>
        </div>
        <div v-else-if="infoType === 'estimate'">
          <li>
            Take measurements and assess the age and state of each element to
            determine their overall condition.
          </li>
          <li>
            Inspect each element for signs of damage, wear and tear, or other
            issues that could affect their performance.
          </li>
          <li>
            If necessary, they may check for storm damage to see if you qualify
            for a replacement from insurance.
          </li>
          <li>
            Once the assessment is complete, the contractor will provide a
            written estimate for the replacement work.
          </li>
        </div>
        <div v-else-if="infoType === 'adjustment'">
          <li>
            Assist the insurance adjuster in assessing the storm damage to your
            property.
          </li>
          <li>
            Together, they will conduct a thorough walk-through of the property,
            carefully examining all areas that may have been affected by the
            storm.
          </li>
          <li>
            The contractor will provide valuable insights and help identify any
            potential damage that may have been missed.
          </li>
          <li>
            They will also check for any discontinued, defective, or warranted
            building materials to ensure that all necessary repairs are
            identified and included in the insurance claim.
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { IonIcon } from "@ionic/vue";
import { closeCircle } from "ionicons/icons";
export default defineComponent({
  components: {
    IonIcon,
  },
  props: {
    infoType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const infoTypeValue = ref(props.infoType);

    watch(props, (newValue) => {
      infoTypeValue.value = newValue.infoType;
    });

    function closeInfo() {
      infoTypeValue.value = "";
    }

    return { closeCircle, infoTypeValue, closeInfo };
  },
});
</script>
<style scoped>
.content-appointment-info {
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3); */
  background-color: gainsboro;
  display: flex;
  flex-direction: column;

  /* justify-content: flex-start; */
  height: auto;
  border-radius: 20px 20px 20px 20px;
  margin: 10px;

  padding-left: 10px;
  overflow: hidden;
  cursor: pointer;
  /* align-content: center; */
}
</style>
