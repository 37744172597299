import { toastController } from "@ionic/vue";

export async function displayPopup(message, timeMS) {
  try {
    const toast = await toastController.create({
      message: message,
      duration: timeMS,
      position: "bottom",
    });

    await toast.present();
  } catch (error) {
    console.error("could not show popup: ", error);
  }
}
