import { createRouter, createWebHistory } from "@ionic/vue-router";
import { onAuthStateChanged } from "firebase/auth";

// firebase imports
import { auth, db, analytics, logEvent } from "../firebase/config";
// import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { routes } from "../routes.js";

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),

  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // let attemptedRoute = to.path;
  // redirect from qr code dummy page and log event before doing anything else
  if (to.name === "qr") {
    logEvent(analytics, "KS_scan_qr_code", {
      time: new Date().toISOString(),
    });
    next("start");
  } else {
    const toRoute = router.resolve(to.path).matched.slice(-1)[0]; // Get the last matched record

    //if route previously existed re-update meta data
    if (toRoute && toRoute.instances.default) {
      toRoute.instances.default.changeMeta();
    }

    onAuthStateChanged(auth, async (user) => {
      // the return is unessecary but stops the router from running any code below it, very slight speed increase and potential bug elimination

      // const user = auth.currentUser;
      async function getDocRef() {
        // console.log("get permissions");
        const docRef = doc(db, "permissions", user.uid);
        const docSnap = await getDoc(docRef);
        let level;
        if (docSnap.data()) {
          level = docSnap.data().level;
        } else {
          level = "zero";
        }
        return { level };
      }

      if (user !== null) {
        const permission = await getDocRef();
        // console.log("got permissions: ", permission);
        //authenticated and permission level verify
        if (permission.level === "verify") {
          if (to.name === "email-verification" || to.name === "access-denied") {
            next();
            return;
          } else {
            next("email-verification");
            return;
          }
        }
        //authenticated and permission level one
        else if (permission.level === "user" || permission.level === "admin") {
          if (
            // can't go to login or signup
            to.name === "contractors" ||
            to.name === "home" ||
            to.name === "start" ||
            to.name === "terms" ||
            to.name === "privacy" ||
            to.name === "contact" ||
            to.name === "FAQ" ||
            to.name === "blog" ||
            to.name === "directory" ||
            to.name === "NotFound" ||
            to.name === "access-denied"

            // || to.name === undefined
          ) {
            next();
            return;
            // else if login,signup,or verify-address
          } else if (
            to.name === "login" ||
            to.name === "signup" ||
            to.name === "verify-address"
          ) {
            next("contractors/home");
            return;
          } else {
            //deny acces
            next("access-denied");
            return;
            // return false;
          }
        } else if (
          permission.level === "owner" ||
          permission.level === "employee"
        ) {
          next("access-denied");
          return;
          // return false;
        } else {
          next("access-denied");
          return;
          //deny access
          // return false;
        }
      }
      // if user is not logged in
      else {
        if (
          to.name === "login" ||
          to.name === "home" ||
          to.name === "signup" ||
          //login and signup allowed when not logged in
          to.name === "start" ||
          to.name === "contractors" ||
          to.name === "terms" ||
          to.name === "privacy" ||
          to.name === "contact" ||
          to.name === "FAQ" ||
          to.name === "blog" ||
          to.name === "directory" ||
          to.name === "NotFound" ||
          to.name === "access-denied"
        ) {
          next();
          return;
        } else {
          next("access-denied");
          return;
          //deny access
          // return false;
        }
      }
    });
  }
});

export default router;
