<template>
  <!-- header -->

  <!-- <ion-list inset="true" v-if="user"> -->
  <!-- <ion-list-header class="list-header-account-acord">
    Dashboard</ion-list-header
  > -->

  <!-- <ion-item v-if="user">{{ user.email }} </ion-item> -->

  <ion-accordion-group>
    <!-- account settings -->

    <ion-accordion>
      <ion-item color="light" slot="header">
        <ion-label>{{ user.email }}</ion-label>
      </ion-item>
      <ion-list slot="content">
        <!-- <ion-item
            ><ion-button fill="clear" class="address-button-account-acord"
              >Edit Account</ion-button
            ></ion-item
          > -->
        <ion-item
          button
          detail="false"
          @click="passwordReset()"
          style="color: navy"
          >Change Password</ion-item
        >
        <ion-item
          v-if="userData"
          button
          style="color: navy"
          detail="false"
          @click="openModal(ChangeEmailModal)"
        >
          Change Email
        </ion-item>

        <ion-item color="light" v-if="errorMessage">{{
          errorMessage
        }}</ion-item>
      </ion-list>
    </ion-accordion>

    <!--  Current Address accordion-->
    <ion-accordion>
      <ion-item color="light" slot="header">
        <ion-label>Address</ion-label>
      </ion-item>
      <ion-list slot="content">
        <ion-item v-if="userData" button style="color: navy" detail="false">
          <p @click="openModal(ChangeAddressModal)">
            <!-- {{ address.type }} <br /> -->
            {{ userData.addressComponents.houseNumber }}
            {{ userData.addressComponents.street }},<br />
            {{ userData.addressComponents.city }},
            {{ userData.addressComponents.state }}
            {{ userData.addressComponents.zip }}
          </p>
        </ion-item>
        <ion-item v-else> No address to show </ion-item>
      </ion-list>
    </ion-accordion>
    <!-- Appointments accordian -->
    <ion-accordion>
      <ion-item color="light" slot="header">
        <ion-label>Appointments</ion-label>
      </ion-item>
      <ion-list slot="content">
        <div v-if="appointments.length">
          <div v-for="appointment in appointments" :key="appointment.id">
            <ion-item
              @click="openModal(ViewAppointmentModal, appointment)"
              button
            >
              <ion-label>
                <div style="font-weight: bold">
                  {{ appointment.appTime }} |
                  {{ appointment.date.dateFormatted }}
                </div>
                <div style="color: grey">{{ appointment.appType }}<br /></div>
                <div style="color: grey">
                  {{ appointment.contractor }}
                </div>
              </ion-label>

              <!-- should make format time a composable -->
              <!-- @click="handleUpdateAppointment(appointment)" -->
            </ion-item>
          </div>
        </div>
        <div v-else><ion-item>No appointments to show</ion-item></div>

        <!-- <ion-item
            ><ion-button
              class="button-account-acord"
              fill="clear"
              @click="() => router.push('/tabs/contractors')"
              >Schedule Appointment</ion-button
            ></ion-item
          > -->
        <!-- can't redirect to another tab in tabs -->
      </ion-list>
    </ion-accordion>
    <ion-accordion>
      <ion-item color="light" slot="header">
        <ion-label>Past Severe Weather</ion-label>
      </ion-item>
      <ion-list slot="content">
        <div v-if="hailStorms.length > 0 || windStorms.length > 0">
          <div v-if="hailStorms.length > 0">
            <ion-item style="--background: #efc05a">
              <ion-label> Hail in {{ userData.city }} </ion-label>
            </ion-item>
            <ion-item v-for="hail in hailStorms" :key="hail">
              <ion-label>{{ hail.stormDate }}</ion-label>
              <div slot="end">{{ hail.size }} in</div>
            </ion-item>
          </div>
          <div v-if="windStorms.length > 0">
            <ion-item color="light"
              >Wind in {{ userData.county }} County<ion-label> </ion-label
            ></ion-item>

            <ion-item v-for="wind in windStorms" :key="wind">
              <ion-label> {{ wind.stormDate }} </ion-label>

              <div slot="end">{{ wind.speed }} mph</div>
            </ion-item>
          </div>
        </div>
        <div v-else><ion-item>No severe weather in last year</ion-item></div>

        <!-- <ion-item
            ><ion-button
              class="button-account-acord"
              fill="clear"
              @click="() => router.push('/tabs/contractors')"
              >Schedule Appointment</ion-button
            ></ion-item
          > -->
        <!-- can't redirect to another tab in tabs -->
      </ion-list>
    </ion-accordion>
    <!--contractor accordian  -->

    <!-- <ion-accordion>
        <ion-item slot="header">
          <ion-label>Contractors</ion-label>
        </ion-item>
        <ion-list slot="content">
           <ion-item>
            <ion-label>Past contractors</ion-label>
          </ion-item> 
          <ion-item
            ><ion-button fill="clear" shape="round" @click="reportContractor()"
              >Report Contractors</ion-button
            ></ion-item
          >
        </ion-list>
      </ion-accordion>   -->

    <!-- <ion-accordion>
        <ion-item slot="header">
          <ion-label>Severe Weather Record</ion-label>
        </ion-item>
        <ion-list slot="content">
          <ion-item>
            <ion-label>Storms</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Days till expire</ion-label>
          </ion-item>
        </ion-list>
      </ion-accordion> -->

    <!-- don't knock my neighbor -->
    <!-- <ion-button class="dkmn-button-account-accord" shape="round"
        >Don't knock my neighbor</ion-button
      > -->
  </ion-accordion-group>
  <!-- </ion-list> -->
</template>

<script>
import {
  IonAccordion,
  IonAccordionGroup,
  // IonButton,
  IonItem,
  IonLabel,
  // IonHeader,
  IonList,
  // IonListHeader,
  // loadingController,
  modalController,
} from "@ionic/vue";

import { defineComponent, ref, onMounted } from "vue";
import { arrowDownCircle } from "ionicons/icons";

import ChangeAddressModal from "../components/ChangeAddressModal.vue";
import ViewAppointmentModal from "../components/ViewAppointmentModal.vue";
import ChangeEmailModal from "../components/ChangeEmailModal.vue";
// import getUser from "@/composables/getUser";
import { getUserData } from "../composables/getUserData.js";
import { db } from "../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";

import { useRouter } from "vue-router";
import { formatTime } from "../composables/formatTime.js";
import { typAsync } from "../composables/typAsync.js";
import { displayPopup } from "../composables/displayPopup.js";
//firebase imports
// import { db } from "../firebase/config";
// import { doc} from "firebase/firestore";
// import { auth } from 'src/boot/firebase'
import { auth } from "../firebase/config";

import { sendPasswordResetEmail } from "firebase/auth";
import moment from "moment";

export default defineComponent({
  // name: "account-acord",

  components: {
    IonAccordion,
    IonAccordionGroup,
    // IonButton,
    IonItem,
    IonLabel,
    IonList,
    // IonHeader,

    // IonListHeader,
  },
  setup() {
    // const { user } = getUser();

    const hailStorms = ref([]);
    const windStorms = ref([]);
    const router = useRouter();
    const currentDate = ref();
    const dateOneYearAgo = ref();
    // const auth = getAuth();
    const errorMessage = ref("");
    // const addresses = ref();

    const appointments = ref([]);
    const user = ref(auth.currentUser);
    const userData = ref();

    onMounted(() => {
      return typAsync(async () => {
        userData.value = await getUserData();
        await getDates();
        await getAppointments();
        await getDocument(
          "hailStorms",
          "cities",
          userData.value.addressComponents.city.toLowerCase()
        );
        await getDocument(
          "windStorms",
          "counties",
          userData.value.addressComponents.county.toLowerCase()
        );
      });
    });

    async function getAppointments() {
      const q = query(
        collection(db, "appointments"),
        where("userId", "==", userData.value.userId)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let status = doc.data().status;
        if (status === "active" || status === "rescheduled") {
          let tempApp = doc.data();

          let appTime = formatTime(tempApp.time);
          let appType = "";

          if (tempApp.type === "inspection") {
            appType = "Storm Damage Inspection";
          } else if (tempApp.type === "adjustment") {
            appType = "Insurance Adjustment";
          } else if (tempApp.type === "estimate") {
            appType = "Replacement Estimate";
          } else {
            appType = "Appointment";
          }

          appointments.value.push({
            ...tempApp,
            id: doc.id,
            appTime: appTime,
            appType: appType,
          });
        }
      });

      // console.log("appointments: ", appointments.value);
    }

    async function getDates() {
      // get current date
      let today = new Date();

      // get current date in DDMMYYYY format
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      let yyyy = today.getFullYear();
      currentDate.value = `${yyyy}${mm}${dd}`;

      // get date 1 year prior
      let oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      let dd1 = String(oneYearAgo.getDate()).padStart(2, "0");
      let mm1 = String(oneYearAgo.getMonth() + 1).padStart(2, "0"); // January is 0!
      let yyyy1 = oneYearAgo.getFullYear();
      dateOneYearAgo.value = `${yyyy1}${mm1}${dd1}`;
    }

    async function getDocument(stormCollection, type, name) {
      const q = query(
        collection(db, stormCollection),
        where(type, "array-contains", name),
        where("stormDate", ">", dateOneYearAgo.value)
      );
      const querySnapshot = await getDocs(q);
      let tempHolder;

      querySnapshot.forEach((doc) => {
        tempHolder = doc.data();

        const date = moment(tempHolder.stormDate, "YYYYMMDD");
        const newDate = date.format("M/D/YY");

        let tempHolder2 = {
          ...tempHolder[name],
          stormDate: newDate,
        };

        if (type === "cities") {
          hailStorms.value = [...hailStorms.value, tempHolder2];
          // console.log("hailStorms: ", hailStorms.value, tempHolder);
        } else if (type === "counties") {
          windStorms.value = [...windStorms.value, tempHolder2];
          // console.log("windStorms: ", windStorms.value);
        }
      });
    }

    // const handleUpdateAppointment = async (appointment) => {

    // const docRef = doc(db, "appointments", appointment.id);
    // await updateDoc(docRef, {
    //   edit: user.value.uid,
    // });
    // router.push("/edit-address");
    // };

    // const reportContractor = async () => {
    //   await showLoading();
    //   router.push("/report-contractor");
    //   loadingController.dismiss();
    // };

    function passwordReset() {
      return typAsync(async () => {
        await sendPasswordResetEmail(auth, userData.value.email);
        await displayPopup("Change password email sent.", 10000);
        // signOut(auth);
        // router.push("/login");
        // errorMessage.value = "";
      });
    }

    async function openModal(modalType, propsToPass) {
      let props = {};
      if (propsToPass) {
        props = {
          userData: userData.value,
          appointment: propsToPass,
        };
      } else {
        props = {
          userData: userData.value,
        };
      }

      const modal = await modalController.create({
        component: modalType,
        componentProps: props,
      });
      modal.present();
      await modal.onWillDismiss();
    }

    // console.log("addresses", addresses.value);
    return {
      arrowDownCircle,
      // addresses,
      appointments,
      userData,
      router,
      hailStorms,
      windStorms,
      // handleUpdateAppointment,
      passwordReset,
      // reportContractor,
      errorMessage,
      user,
      openModal,
      ChangeAddressModal,
      ViewAppointmentModal,
      ChangeEmailModal,
    };
  },
});
</script>

<style>
.list-header-account-acord {
  font-size: 30px;
}
.dkmn-button-account-accord {
  width: 80%;
}
.button-account-acord {
  width: auto;
}
.edit-button-account-acord {
  width: 70px;
}
</style>
