<template>
  <modal-base>
    <template v-slot:modalContent>
      <ion-list>
        <ion-item color="light">
          <ion-label><h1>Change Email</h1></ion-label>
        </ion-item>
        <ion-item>
          <ion-label>Current Email:</ion-label>
          {{ user.email }}
        </ion-item>
        <ion-item
          ><ion-label>New Email:</ion-label>
          <ion-input
            type="email"
            name="email"
            placeholder="email@provider.com"
            v-model="email"
          />

          <ion-checkbox
            :disabled="true"
            v-model="emailValidation"
          ></ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-label>Password:</ion-label>
          <ion-input
            type="password"
            name="password"
            placeholder="enter password"
            v-model="password"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="passwordEntered"
          ></ion-checkbox>
        </ion-item>
        <ion-item v-if="errorMessage">
          {{ errorMessage }}
        </ion-item>
      </ion-list>
    </template>

    <template v-slot:submissionFooter>
      <ion-button
        v-if="emailValidation && passwordEntered && email !== user.email"
        expand="block"
        @click="changeEmail(email)"
        >Submit</ion-button
      >
      <ion-button v-else :disabled="true" expand="block">Submit</ion-button>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import { typAsync } from "../composables/typAsync.js";
import { db } from "../firebase/config";
import { defineComponent, ref, watch } from "vue";
import { doc, updateDoc } from "firebase/firestore";
import { useRouter } from "vue-router";
import { auth } from "../firebase/config";
import {
  updateEmail,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";

import {
  IonButton,
  IonInput,
  modalController,
  IonItem,
  IonLabel,
  IonList,
  IonCheckbox,
} from "@ionic/vue";
// import TermsOfService from "../components/TermsOfService.vue";

// import { IonContent } from "@ionic/vue";

export default defineComponent({
  components: {
    ModalBase,
    IonInput,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonCheckbox,
  },

  setup() {
    const emailValidation = ref(false);
    const email = ref("");
    const router = useRouter();

    const password = ref("");
    const user = auth.currentUser;
    const errorMessage = ref("");
    const passwordEntered = ref(false);

    // console.log("user", user);
    // console.log(typeof user);
    // console.log(Object.getPrototypeOf(user));

    watch(email, () => {
      if (validateEmail(email.value)) {
        emailValidation.value = true;
      } else {
        emailValidation.value = false;
      }
    });

    watch(password, () => {
      if (password.value) {
        passwordEntered.value = true;
      } else {
        passwordEntered.value = false;
      }
    });

    function validateEmail(email) {
      var emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(email)) {
        return false;
      }

      return true;
    }

    function changeEmail(newEmail) {
      // create credential
      if (emailValidation.value) {
        let credential = EmailAuthProvider.credential(
          user.email,
          password.value
        );

        // console.log("credential:", credential);
        return typAsync(async () => {
          // await user.reauthenticateWithCredential(credential);
          await reauthenticateWithCredential(user, credential);
          await updateEmail(user, newEmail);
          await sendEmailVerification(auth.currentUser);
          await updateDoc(doc(db, "permissions", user.uid), {
            level: "verify",
          });

          location.reload();
          router.push("/email-verification");
          modalController.dismiss(null, "cancel");
        });
      } else {
        errorMessage.value = "please enter all information";
      }
    }

    return {
      user,
      changeEmail,
      email,
      validateEmail,
      emailValidation,
      password,
      errorMessage,
      passwordEntered,
    };
  },
});
</script>
