export async function findDistance(
  userLat,
  userLng,
  contractorLat,
  contractorLng
) {
  let mPi = Math.PI / 180;
  let pOne = userLat * mPi;
  let pTwo = contractorLat * mPi;
  let dOne = (contractorLat - userLat) * mPi;
  let dTwo = (contractorLng - userLng) * mPi;
  let distance = "error";

  let a =
    Math.sin(dOne / 2) * Math.sin(dOne / 2) +
    Math.cos(pOne) * Math.cos(pTwo) * Math.sin(dTwo / 2) * Math.sin(dTwo / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = 3956.391 * c;
  distance = d.toFixed(1);

  return distance;
}
