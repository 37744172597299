<template>
  <!-- searchbar -->
  <ion-searchbar
    v-if="openDisplay === false || contractorSelected === false"
    v-model="input"
    placeholder="search contractors"
    style="height: 55px"
    @input="getContractors()"
  ></ion-searchbar>

  <!-- this shows the contractors filtered from input -->
  <div v-if="input && contractorsLoaded">
    <ion-list v-for="contractor in filteredList()" :key="contractor">
      <ion-item @click="getContractorFromAll(contractor.contractorId)">{{
        contractor.name
      }}</ion-item>
    </ion-list>
    <!-- this shows if no contractors were found from filtered list -->
    <ion-item v-if="input && !filteredList().length && contractorsLoaded"
      >No results found</ion-item
    >
  </div>
  <!-- contractor display if modal is false -->
  <div v-if="openDisplay === true && contractorSelected === true">
    <contractor-display :contractorData="contractor"></contractor-display>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { useAllContractors } from "../composables/getAllContractors.js";
// firebase imports
import { db } from "../firebase/config";
import {
  // collection,
  // query,
  // where,
  // getDocs,
  // updateDoc,
  // addDoc,
  doc,
  getDoc,
  // deleteDoc,
} from "firebase/firestore";

import ContractorDisplay from "../components/ContractorDisplay.vue";

import { IonSearchbar, IonItem, IonList } from "@ionic/vue";

import { findDistance } from "../composables/findDistance.js";
import { typAsync } from "../composables/typAsync.js";
export default defineComponent({
  emits: ["contractor-updated"],
  props: {
    userData: {
      type: Object,
      required: false,
      default: null,
    },
    openDisplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchCoords: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    IonSearchbar,
    IonItem,
    IonList,
    ContractorDisplay,
  },
  setup(props) {
    const coords = ref(props.searchCoords);
    if (props.userData) {
      coords.value = {
        lat: props.userData.geodata.lat,
        lng: props.userData.geodata.lng,
      };
    }

    watch(props, (newProps) => {
      if (newProps.userData) {
        coords.value = {
          lat: props.userData.geodata.lat,
          lng: props.userData.geodata.lng,
        };
      } else {
        coords.value = newProps.searchCoords;
      }
    });

    // console.log("userDAta", userData);
    const allContractors = ref([]);
    const contractorsLoaded = ref(false);
    const input = ref();
    const contractor = ref();
    const contractorSelected = ref(false);
    // getContractors();

    async function getContractors() {
      let { allContractorsValues } = await useAllContractors();
      allContractors.value = allContractorsValues;
      contractorsLoaded.value = true;
      // console.log("allContractors:", allContractors.value);
    }

    async function getContractorFromAll(documentId) {
      return typAsync(async () => {
        let searchBase = `contractors`;

        let docRef = doc(db, searchBase, documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data()) {
          // console.log("docSnap", docSnap.data());
          let contractorLat = docSnap.data().geodata.lat;
          let contractorLng = docSnap.data().geodata.lng;
          let multiplier = docSnap.data().multiplier;
          let radius = docSnap.data().radius;
          let d = await findDistance(
            coords.value.lat,
            coords.value.lng,
            contractorLat,
            contractorLng
          );

          if (multiplier > 1 && d > radius) {
            multiplier = 1;
          }

          // console.log("serach contractoru id: ", docSnap.data().contractorUid);
          contractor.value = {
            ...docSnap.data(),
            distance: d,
            multiplier: multiplier,
          };
          this.$emit("contractor-updated", contractor.value);
          contractorSelected.value = true;
          input.value = "";
          // console.log("modalData: ", modalData.value);
        } else {
          console.error(
            "Searched for contractor and document not found in database"
          );
        }
      });
    }

    function filteredList() {
      //this is for searchbar
      // console.log(contractorHolder);

      return allContractors.value.filter((contractor) =>
        contractor.name.toLowerCase().includes(input.value.toLowerCase())
      );
    }

    return {
      filteredList,
      getContractorFromAll,
      input,
      getContractors,
      contractorsLoaded,

      contractor,
      contractorSelected,
    };
  },
});
</script>

<style></style>
