import { toastController } from "@ionic/vue";
import { analytics, auth, logEvent } from "../firebase/config";

export async function displayError(error) {
  try {
    const toast = await toastController.create({
      message: `Error Reported: ${error.message}`,
      duration: 3000,
      position: "bottom",
    });

    logEvent(analytics, "knock_stoppers_error", {
      error_message: error.message,
      error_name: error.name,
      error_stack_trace: error.stack,
      user: auth.currentUser,
      time: new Date().toISOString(),
      url: window.location.href,
    });
    console.error("Reported Error: ", error);
    await toast.present();
  } catch (error) {
    console.error("could not log error event: ", error);
  }
}
