<!-- https://vue3datepicker.com/ -->
<!-- https://antoniandre.github.io/vue-cal/#api -->
<template>
  <modal-base>
    <template v-slot:modalContent>
      <div v-if="!contractorData">
        <ion-item v-if="!contractorDataScoped" color="light"
          >Search for a contractor:</ion-item
        >

        <contractor-search
          :userData="userData"
          :openDisplay="true"
          @contractor-updated="updateContractor"
        ></contractor-search>
      </div>
      <div v-else>
        <contractor-display
          :contractorData="contractorData"
        ></contractor-display>
      </div>

      <ion-list>
        <!-- knocks -->
        <div v-if="reviewType === 'knock'">
          <ion-item>
            <ion-label>Select</ion-label>
            <ion-select placeholder="Make a Selection" v-model="canvasType">
              <ion-select-option value="knock"
                >Knocked my door</ion-select-option
              >
              <ion-select-option value="marketing"
                >Left Marketing Material</ion-select-option
              >
              <ion-select-option value="call"
                >Unsolicited phone call</ion-select-option
              >
            </ion-select>
          </ion-item>

          <calendar-popover
            :options="reviewDateOptions"
            @date-selected="updateReviewDate"
            ref="clearStartDate"
          ></calendar-popover>

          <ion-item color="light"
            ><ion-label> Describe your experience below</ion-label></ion-item
          >
        </div>

        <!-- reviews -->
        <div>
          <ion-item>
            <ion-label>Select Rating:</ion-label>
            <p style="padding-right: 10px">{{ starValue }}</p>
            <star-rating
              :startingStars="0"
              :editStars="true"
              :showRating="false"
              @rating-updated="updateStarValue"
            ></star-rating>
          </ion-item>
          <!-- <ion-item>
            <ion-label>Name: </ion-label>
            <ion-input
              type="text"
              pattern="[a-zA-Z]+"
              v-model="name"
              placeholder="Your Name"
              maxlength="50"
            ></ion-input>
          </ion-item> -->

          <ion-item counter="true">
            <ion-label>Enter Review:</ion-label>
            <ion-textarea
              v-model="reviewText"
              placeholder="optional"
              :autoGrow="true"
              maxlength="1000"
            ></ion-textarea>
            <ion-button
              v-if="reviewText"
              shape="round"
              fill="outline"
              @click="clearReview()"
              >X</ion-button
            >
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-button id="terms-trigger" color="light"
                >Terms and Conditions</ion-button
              ></ion-label
            >
            <ion-checkbox v-model="terms" :disabled="true"></ion-checkbox>
          </ion-item>
          <ion-modal trigger="terms-trigger" id="ion-modal-review-terms">
            <ion-content class="ion-padding">
              <ion-item color="light">
                <ion-label> Terms and Conditions: </ion-label>
              </ion-item>

              <div style="padding: 10px">
                <review-terms></review-terms>
              </div>
              <ion-item color="light">
                <ion-label>
                  I've read and agree to these terms and conditions</ion-label
                >

                <ion-checkbox v-model="terms"></ion-checkbox>
              </ion-item>
            </ion-content>
          </ion-modal>
        </div>
        <ion-item v-if="errorMessage">
          {{ errorMessage }}
        </ion-item>
      </ion-list>
    </template>
    <template v-slot:reviewFooter>
      <ion-button v-if="terms" expand="block" @click="submitThis()"
        >Submit</ion-button
      >
      <ion-button v-else :disabled="true" expand="block">Submit</ion-button>
    </template>
  </modal-base>
</template>

<script>
// import { doc, updateDoc } from "firebase/firestore";
// import { auth, db } from "../firebase/config";
// import VueCal from "vue-cal";
// import "vue-cal/dist/vuecal.css";
import { profanityFilter } from "../composables/profanityFilter.js";
import CalendarPopover from "../components/CalendarPopover.vue";
import {
  // IonTitle,
  IonContent,
  // IonToolbar,
  // IonButtons,
  IonModal,
  IonCheckbox,
  IonItem,
  // IonIcon,
  IonLabel,
  // IonInput,
  // IonPopover,
  IonList,
  // IonRange,
  // IonSegment,
  // IonSegmentButton,
  // IonItemOption,
  // IonItemOptions,
  IonButton,
  // IonToggle,
  // IonRadio,
  // IonRadioGroup,
  IonSelect,
  IonSelectOption,
  // IonItemSliding,
  IonTextarea,
  modalController,
  // loadingController,
} from "@ionic/vue";
import { defineComponent, ref, watch, onMounted } from "vue";

import ModalBase from "../components/ModalBase.vue";
import ReviewTerms from "../components/ReviewTerms.vue";
import StarRating from "../components/StarRating.vue";
import ContractorDisplay from "../components/ContractorDisplay.vue";
// import ContractorSearch from "../components/ContractorSearch.vue";
import ContractorSearch from "../components/ContractorSearch.vue";
import { typAsync } from "../composables/typAsync.js";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/config";
// import ScheduleModal from "../components/ScheduleModal.vue";

// import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";

export default defineComponent({
  props: {
    contractorData: {
      type: Object,
      required: false,
    },
    userData: {
      type: Object,
      required: true,
    },
    reviewType: {
      type: String,
      required: true,
    },
    // openSearch: {
    //   type: Boolean,
    // },
  },

  components: {
    // IonTitle,
    // IonToolbar,
    // IonButtons,
    CalendarPopover,
    IonContent,
    IonButton,
    IonItem,
    IonLabel,
    IonModal,
    ReviewTerms,
    IonCheckbox,
    // IonIcon,
    // IonInput,
    IonTextarea,
    // IonRange,
    // IonPopover,
    IonList,
    // IonSegment,
    // IonSegmentButton,
    // IonItemSliding,
    // IonItemOption,
    // IonItemOptions,
    // IonPopover,
    // VueCal,
    // IonRadio,
    // IonRadioGroup,
    // IonToggle,
    IonSelect,
    IonSelectOption,
    ContractorSearch,
    ModalBase,
    StarRating,
    ContractorDisplay,
    // VueCal,
    // ScheduleModal,
  },
  setup(props) {
    const reviewType = ref(props.reviewType);
    const dateSelected = ref();
    const dateFormatted = ref();
    const starValue = ref("select stars");
    const contractorDataScoped = ref(props.contractorData);
    const reviewText = ref("");
    const canvasType = ref("");
    const errorMessage = ref("");
    const terms = ref(false);

    const userData = ref(props.userData);
    const minimumDate = ref("");

    let tempDate = new Date();
    let currentDate = `${tempDate}`;
    let lastWeek = new Date();
    lastWeek.setDate(tempDate.getDate() - 7);

    const reviewDateOptions = ref({
      disableDays: true,
      selectDay: false,
      hideWeekdays: [], //1-7
      time: false,
      transitions: false,
      activeView: "month",
      disableViews: ["week", "day", "year", "years"],
      style: "width: 180px; height: auto",
      title: "select date:",
      trig: "review-date-trigger",
      // date: offseasonStart.value,
      minDate: minimumDate,
      maxDate: "currentDate",
    });

    onMounted(() => {
      checkUserDate();
    });

    function checkUserDate() {
      let userDate = new Date(userData.value.addressDate);

      if (userDate > lastWeek) {
        minimumDate.value = userDate;
      } else {
        minimumDate.value = lastWeek;
      }
    }

    function getSubmissionBase(filteredText) {
      let submissionBase = {
        contractorId: contractorDataScoped.value.contractorId,
        userId: userData.value.userId,
        rating: starValue.value,
        text: filteredText,
        name: userData.value.name,
        terms: terms.value,
        publishedDate: `${currentDate}`,
      };

      return submissionBase;
    }

    function updateReviewDate(emitValues) {
      // console.log("emit values: ", emitValues);
      dateSelected.value = emitValues;
    }

    async function filterText(text) {
      let { filteredText } = await profanityFilter(text);
      return filteredText.value;
    }

    watch(props, (newProps) => {
      userData.value = newProps.userData;
      contractorDataScoped.value = newProps.contractorData;
    });

    function formatDate(date) {
      let tempArr = `${date}`;
      tempArr = tempArr.split(" ");
      tempArr = `${tempArr[0]}, ${tempArr[1]} ${tempArr[2]} ${tempArr[3]}`;
      return tempArr;
    }

    function updateStarValue(value) {
      starValue.value = value;
    }

    function updateContractor(value) {
      contractorDataScoped.value = value;
    }

    function clearReview() {
      reviewText.value = "";
    }

    async function updateDocument() {
      let overallRating = contractorDataScoped.value.reviews.overallRating;
      let totalReviews = contractorDataScoped.value.reviews.totalReviews;
      let newReviews = totalReviews + 1;
      let newRating =
        (totalReviews * overallRating + starValue.value) / newReviews;

      await updateDoc(
        doc(db, "contractors", contractorDataScoped.value.contractorId),
        {
          reviews: {
            overallRating: newRating,
            totalReviews: newReviews,
          },
        }
      );
    }

    async function submitForm(data, type) {
      if (data && type) {
        let knock = Number(contractorDataScoped.value.knocks.counter) + 1;

        await addDoc(collection(db, type), data);
        await updateDoc(
          doc(db, "contractors", contractorDataScoped.value.contractorId),
          {
            "knocks.counter": knock,
          }
        );
      }
    }

    async function submitReview(submissionBase) {
      await new Promise((resolve, reject) => {
        if (
          contractorDataScoped.value.contractorId &&
          userData.value.userId &&
          starValue.value &&
          starValue.value !== "select stars"
        ) {
          errorMessage.value = "";

          let submission = {
            ...submissionBase,
            date: currentDate,
            dateFormatted: formatDate(tempDate),
          };

          submitForm(submission, "reviews");
          updateDocument();
          resolve();
        } else {
          errorMessage.value = "Please fill out all information";
          reject(new Error("Please fill out all information"));
        }
      });
    }

    async function submitKnock(submissionBase) {
      let submission = {
        ...submissionBase,
        ...userData.value.addressComponents,
        dateFormatted: dateSelected.value.dateFormatted,
        date: dateSelected.value.date,
        canvasType: canvasType.value,
      };

      await new Promise((resolve, reject) => {
        if (
          contractorDataScoped.value.contractorId &&
          userData.value.userId &&
          dateSelected.value &&
          starValue.value &&
          canvasType.value &&
          starValue.value !== "select stars"
        ) {
          errorMessage.value = "";

          submitForm(submission, "knocks");
          submitReview(submissionBase);
          resolve();
        } else {
          errorMessage.value = "Please fill out all information";
          reject(new Error("Please fill out all information"));
        }
      });
    }

    async function submitThis() {
      return typAsync(async () => {
        let filteredText = await filterText(reviewText.value);
        let submissionBase = getSubmissionBase(filteredText);

        // if also submit door knock
        if (reviewType.value === "review") {
          await submitReview(submissionBase);
        } else {
          await submitKnock(submissionBase);
        }

        if (!errorMessage.value) {
          // return modalController.dismiss(null, "cancel");

          // console.log("error: ", errorMessage.value);
          await modalController.dismiss();
        }
      });
    }

    return {
      starValue,
      updateStarValue,
      reviewText,
      clearReview,
      submitThis,
      updateContractor,
      contractorDataScoped,
      dateSelected,
      dateFormatted,
      lastWeek,
      currentDate,
      errorMessage,
      canvasType,
      terms,
      reviewDateOptions,
      updateReviewDate,
    };
  },
});
</script>

<style scoped>
#ion-modal-review-terms {
  --height: 50%;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}
</style>
