<template>
  <!-- <Suspense> -->
  <!-- <template #default> -->

  <ion-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} </template>
    </metainfo>
    <ion-menu content-id="main-content">
      <!-- <ion-header>
        <ion-toolbar>
          <ion-title><h1>Menu Content</h1></ion-title>
        </ion-toolbar>
      </ion-header> -->
      <ion-content>
        <!-- <ion-list
        ><ion-item @click="openModal(BlockModal)">Edit Blocks</ion-item>
        <ion-item @click="openModal(LocationsModal)">Edit Locations</ion-item>
        </ion-list> -->

        <!-- <nav-bar></nav-bar> -->
        <ion-list>
          <ion-item color="primary">
            <ion-label><h1 style="color: gainsboro">Dashboard</h1></ion-label>
          </ion-item>
          <div v-if="userPermission === 'user'" class="dashboard-baseLayout">
            <account-acord></account-acord>
          </div>
          <div v-else>
            <ion-item
              color="light"
              button
              @click="goToThis('login')"
              detail="false"
            >
              <ion-label>Login to see dashboard</ion-label>
            </ion-item>
          </div>
          <ion-item color="primary">
            <ion-label>
              <h1 style="color: gainsboro">Pages</h1>
            </ion-label>
          </ion-item>
          <ion-item
            color="light"
            button
            detail="false"
            @click="goToThis('contractors/home')"
            >Find Contractors</ion-item
          >

          <ion-item
            color="light"
            button
            detail="false"
            @click="goToThis('directory')"
            >Blog</ion-item
          >
          <ion-item color="primary">
            <ion-label>
              <h1 style="color: gainsboro">Tools</h1>
            </ion-label>
          </ion-item>
          <ion-item
            color="light"
            button
            detail="false"
            @click="openModal(ReviewModal, 'knock')"
          >
            Report Contractors
          </ion-item>
          <ion-item
            color="light"
            button
            detail="false"
            @click="openModal(ReviewModal, 'review')"
            >Review Contractor</ion-item
          >
          <no-soliciting-sign></no-soliciting-sign>

          <!-- <ion-item button detail="false">FAQ</ion-item> -->
        </ion-list>
      </ion-content>

      <ion-footer>
        <div style="padding-left: 5px; padding-right: 5px">
          <ion-button
            class="button-app-vue"
            v-if="userPermission === 'user'"
            expand="block"
            @click="
              share(
                'Check out this website!',
                'Reduce door knockers at your home!',
                'www.knockstoppers.com',
                'Knock Stoppers'
              )
            "
            >Don't knock my neighbor</ion-button
          >
          <ion-button
            class="button-app-vue"
            v-else-if="userPermission === 'zero'"
            expand="block"
            @click="goToThis('start')"
            >Don't knock my door</ion-button
          >
        </div>

        <div class="div-menu-APP">
          <a class="font-APP" @click="goToThis('FAQ')">FAQ</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('contact')">Contact</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('terms')">Terms</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('privacy')">Privacy</a>
        </div>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>

  <!-- <template #fallback>
      <LoadingSkeleton />
    </template> -->
  <!-- </Suspense> -->
</template>

<script>
// import LoadingSkeleton from "./components/LoadingSkeleton.vue";
import {
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonFooter,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  // IonHeader,
  modalController,
  loadingController,
  IonContent,
  // IonTitle,
  // IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import AccountAcord from "@/components/AccountAcord.vue";
import ReviewModal from "@/components/ReviewModal.vue";
import NoSolicitingSign from "@/components/NoSolicitingSign.vue";
import { getUserData } from "@/composables/getUserData.js";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import { shareThis } from "./composables/shareThis.js";
// import { defineMeta } from "vue-3-meta";
import { useMeta } from "vue-meta";

import { auth, db } from "./firebase/config";
import { doc, getDoc } from "firebase/firestore";

import photoOne from "./images/KS_LOGO_COLOR_200x95.png";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonMenu,
    IonFooter,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    // IonHeader,
    AccountAcord,
    IonContent,
    // IonTitle,
    // IonToolbar,
    NoSolicitingSign,
    // LoadingSkeleton,
  },

  setup() {
    useMeta({
      title: "Knock Stoppers - Say goodbye to door-to-door", //60 character max
      description:
        "Tired of door knockers and shady roofing contractors? Decrease door-to-door sales at your home, while connecting with top-rated professionals near you.", //155 charactrer max
      og: {
        title: "Knock Stoppers - Say goodbye to door-to-door",
        description:
          "Tired of door knockers and shady roofing contractors? Decrease door-to-door sales at your home, while connecting with top-rated professionals near you.",
        image: photoOne,
      },
      twitter: {
        card: "summary",
        title: "Knock Stoppers - Say goodbye to door-to-door",
        description:
          "Tired of door knockers and shady roofing contractors? Decrease door-to-door sales at your home, while connecting with top-rated professionals near you.",
        image: photoOne,
      },
    });

    const userData = ref("");
    const userPermission = ref("zero");
    // const { user } = getUser();
    const showLoading = async () => {
      const loading = await loadingController.create({
        message: "Loading...",
      });
      loading.present();
    };
    const router = useRouter();

    async function goToThis(pageName) {
      await showLoading();

      router.push(`/${pageName}`);

      loadingController.dismiss();
    }

    onAuthStateChanged(auth, async (user) => {
      console.log("on auth state changed");
      // return typAsync(async () => {
      try {
        if (user) {
          const docSnap = await getDoc(doc(db, "permissions", user.uid));

          if (docSnap.data()) {
            userPermission.value = docSnap.data().level;
            userData.value = await getUserData();
          } else {
            userPermission.value = "zero";
          }
        } else {
          userPermission.value = "zero";
        }
      } catch (error) {
        console.log("on auth state change error");
      }
      // });
    });

    async function openModal(modalType, reviewType) {
      // console.log(modalType, userData.value);
      if (userPermission.value === "user") {
        const modal = await modalController.create({
          component: modalType,

          componentProps: {
            userData: userData.value,
            reviewType: reviewType,
          },
        });
        modal.present();
        await modal.onWillDismiss();
      } else {
        goToThis("login");
      }
    }

    async function share(title, text, url, dialogTitle) {
      await shareThis(title, text, url, dialogTitle);
    }

    return {
      goToThis,
      userData,
      ReviewModal,
      openModal,
      share,
      userPermission,
    };
  },
});
</script>

<style scoped>
.div-menu-APP {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 110px;

  align-items: center;
  justify-content: center;
  /* max-height: 100px; */
  /* margin-top: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  color: grey;
}

.font-APP {
  color: grey;
}

.font-APP:hover {
  cursor: pointer;
  opacity: 0.8;
}

.button-app-vue {
  --background: #efc05a;
  --color: black;
}
</style>
