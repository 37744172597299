<template>
  <h3></h3>
  <p>
    By submitting a review of a contractor or reporting unsolicited marketing
    activities, such as door knocking, marketing material left at your door, or
    unsolicited phone calls, on this platform, you (the "Reviewer") agree to the
    following terms and conditions:
  </p>
  <p>
    You certify that the information provided in your review or report is true,
    accurate, and based on your own personal experience with the contractor or
    the unsolicited marketing activities, to the best of your knowledge.
  </p>
  <p>
    You grant us a non-exclusive, royalty-free, and perpetual license to
    publish, display, and use your review or report on our platform and in any
    promotional materials or channels, including but not limited to our website
    and social media.
  </p>
  <p>
    You acknowledge that your review or report will be publicly visible and
    accessible by other users of the platform.
  </p>
  <p>
    You agree that we may moderate your review or report to ensure it complies
    with our content guidelines, which includes editing it for grammar,
    spelling, or clarity, or removing it entirely if it violates our guidelines
    or any applicable laws.
  </p>
  <p>
    You understand that we reserve the right to refuse to publish your review or
    report or to remove it from our platform at our sole discretion, for reasons
    including but not limited to violations of our content guidelines, suspected
    fraud or fake reviews, or noncompliance with applicable laws or regulations.
  </p>
  <p>
    You warrant that your review or report does not infringe on any third-party
    rights, including but not limited to intellectual property rights, and that
    you have obtained all necessary permissions and consents from any relevant
    parties prior to submitting the review or report.
  </p>
  <p>
    You agree to indemnify, defend, and hold us harmless from and against any
    and all claims, damages, liabilities, costs, and expenses, including
    reasonable attorney fees, arising from your review or report or any breach
    of these terms and conditions.
  </p>
  <p>
    You acknowledge that we will display your name as it appears on your account
    when you submit the review or report, and that this is your actual name. You
    agree not to use any false or misleading information when creating your
    account or submitting a review or report.
  </p>
  <p>
    You acknowledge that we may contact you regarding your review, report, or
    for any other purposes related to the platform, using the contact
    information you provide.
  </p>
  <p>
    By submitting your review or report, you agree to these terms and conditions
    in their entirety. If you do not agree with any of these terms and
    conditions, please refrain from submitting your review or report.
  </p>
</template>
