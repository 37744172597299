import { db } from "../firebase/config";
import {
  doc,
  getDoc,
  // getDocs  collection,
} from "firebase/firestore";

export async function useAllContractors() {
  let allContractorsValues = [];
  // const querySnapshot = await getDocs(collection(db, "ALLcontractors"));
  const docRef = doc(db, "allContractors", "allContractors");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    allContractorsValues = Object.values(docSnap.data());
    // console.log("document found get all contractors");
  } else {
    console.error(
      "Error: get all contractors failed to retrieve allContractors from contractors"
    );
  }

  // querySnapshot.forEach((doc) => {
  //   allContractorsValues.push(doc.data());
  // });

  return { allContractorsValues };
}
