<template>
  <ion-item v-if="displayAddressButton && !searchBar">
    <ion-button
      color="light"
      expand="block"
      style="width: 100%"
      @click="openSearchBar()"
      >{{ displayAddress }}</ion-button
    >

    <ion-checkbox
      v-if="displayCheckBox"
      slot="end"
      :disabled="true"
      v-model="addressValidation"
    ></ion-checkbox>
  </ion-item>

  <div style="width: 100%" v-if="searchBar || !displayAddressButton">
    <div style="display: flex; align-items: center; align-content: center">
      <div
        style="
          height: 100%;
          width: 30px;
          align-items: center;
          align-content: center;
          justify-content: center;
        "
      >
        <ion-icon
          :icon="chevronBackCircle"
          @click="toggleSearch()"
          style="color: grey; margin: 0; padding: 0; width: 30px; height: 30px"
          slot="start"
        ></ion-icon>
      </div>

      <ion-searchbar
        ref="searchbarCursor"
        style="padding: 0; margin: 0"
        :debounce="1000"
        @ionChange="getAutocompleteSuggestions()"
        v-model="query"
        placeholder="search addresses"
      ></ion-searchbar>
    </div>
    <div v-if="query">
      <div v-if="results.length">
        <div v-for="result in results" :key="result">
          <ion-button
            color="light"
            expand="full"
            size="small"
            style="padding-right: 10px; padding-left: 10px"
            @click="selectAddress(result)"
          >
            <span class="addressValues-autocomplete">
              {{ result.address.freeformAddress }}</span
            >
          </ion-button>
        </div>
      </div>
      <div v-else>
        <ion-button slot="start" color="light" expand="full" size="small"
          >No results</ion-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import {
  IonSearchbar,
  IonButton,
  IonItem,
  IonCheckbox,
  IonIcon,
} from "@ionic/vue";
import { chevronBackCircle } from "ionicons/icons";
import { getPermanantGeocode } from "../composables/getPermanantGeocode.js";
import { typAsync } from "../composables/typAsync.js";
export default defineComponent({
  components: {
    IonSearchbar,
    IonButton,
    IonItem,
    IonCheckbox,
    // IonItem
    IonIcon,

    // IonLabel,
  },
  props: {
    displayAddressButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayCheckBox: {
      type: Boolean,
      required: false,
      default: false,
    },
    startingAddress: {
      type: String,
      required: false,
      default: "",
    },
    requireFullAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["search-location", "close-search"],
  setup(props, context) {
    const query = ref("");
    const results = ref([]);
    const searchBar = ref(false);
    const addressValidation = ref(false);
    const displayAddress = ref("search and select address");
    const addressValues = ref("");
    const startingValue = ref(props.startingAddress);
    const searchbarCursor = ref(null);

    if (startingValue.value) {
      displayAddress.value = startingValue.value;
      addressValidation.value = true;
    }

    function openSearchBar() {
      console.log("open searchbar");

      searchBar.value = true;
    }

    function handleSearchbarFocus() {
      console.log("open searchbar");
      searchBar.value = true;

      setTimeout(() => {
        const input = searchbarCursor.value.$el.querySelector("input");
        if (input) {
          input.focus();
        }
      }, 0);
    }

    function getAutocompleteSuggestions() {
      return typAsync(async () => {
        // console.log("tried to get autocomplete sugjestions");

        if (query.value !== "") {
          const apiUrl = `https://api.tomtom.com/search/2/search/${encodeURIComponent(
            query.value
          )}.json?key=${
            process.env.VUE_APP_TOM_TOM_API_KEY
          }&typeahead=true&limit=5&language=en-US&countrySet=US`;

          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.results) {
            results.value = [];
            let ttResults = data.results;
            // console.log("data.results: ", ttResults);

            if (props.requireFullAddress) {
              for (let i = 0; i < ttResults.length; i++) {
                if (
                  ttResults[i].address.streetNumber &&
                  ttResults[i].address.streetName &&
                  ttResults[i].address.localName &&
                  ttResults[i].address.countrySubdivision &&
                  ttResults[i].address.postalCode
                ) {
                  results.value.push(ttResults[i]);
                }
              }
            } else {
              results.value = ttResults;
            }
          } else {
            results.value = [];
          }
        } else {
          results.value = [];
        }
        handleSearchbarFocus();
      });
    }

    function selectAddress(result) {
      return typAsync(async () => {
        if (result) {
          addressValues.value = result.address;

          let address = {
            street: `${addressValues.value.streetNumber} ${addressValues.value.streetName}`,
            city: addressValues.value.localName,
            state: addressValues.value.countrySubdivision,
            zip: addressValues.value.postalCode,
          };

          let { permanantGeocode } = await getPermanantGeocode(address);

          if (permanantGeocode) {
            // console.log("permanantGeoCode: ", permanantGeocode);
            query.value = "";
            results.value = [];

            if (props.requireFullAddress) {
              let address = permanantGeocode.addressComponents;
              let geo = permanantGeocode.geodata;
              if (
                address.city &&
                address.county &&
                address.houseNumber &&
                address.state &&
                address.street &&
                address.zip &&
                permanantGeocode.formattedAddress &&
                geo.lat &&
                geo.lng
              ) {
                emitValues(permanantGeocode);
              } else {
                if (props.displayAddressButton) {
                  searchBar.value = false;
                  addressValidation.value = false;
                  displayAddress.value = "try again";
                }
              }
            } else {
              emitValues(permanantGeocode);
            }
          }
        } else {
          addressValues.value = "could not find data for address";
        }
      });
    }

    function toggleSearch() {
      searchBar.value = false;
      context.emit("close-search");
    }

    function emitValues(permanantGeocode) {
      if (props.displayAddressButton) {
        displayAddress.value = permanantGeocode.formattedAddress;
        searchBar.value = false;
        addressValidation.value = true;
      }

      context.emit("search-location", permanantGeocode, chevronBackCircle);
    }

    return {
      searchbarCursor,
      getAutocompleteSuggestions,
      query,
      results,
      selectAddress,
      searchBar,
      openSearchBar,
      addressValidation,
      displayAddress,
      toggleSearch,
      chevronBackCircle,
    };
  },
});
</script>
<style scoped>
.addressValues-autocomplete {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
