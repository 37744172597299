<template>
  <div>
    <!-- <nav> -->
    <!-- for logged in users -->
    <div v-if="user">
      <!-- <router-link to="/">Start</router-link> -->
      <ion-button
        class="button-nav-bar"
        slot="end"
        shape="round"
        fill="clear"
        @click="handleLogoutClick()"
        >Logout</ion-button
      >
    </div>

    <!-- for logged out users -->
    <div v-else-if="!user">
      <!-- <router-link to="/login">Login</router-link> -->

      <ion-button
        v-if="routeName === 'login'"
        class="button-nav-bar"
        shape="round"
        fill="clear"
        @click="handleButtonClick('signup')"
        >Signup</ion-button
      >
      <ion-button
        v-else
        class="button-nav-bar"
        shape="round"
        fill="clear"
        @click="handleButtonClick('login')"
        >Login</ion-button
      >
      <!-- <ion-button @click="() => router.push('/signup')">Signup</ion-button> -->
      <!-- <router-link to="/signup">Signup</router-link> -->
    </div>
    <!-- </nav> -->
    <!-- show use email -->
    <!-- <div class="loginEmail" v-if="user">Hello {{ user.email }}</div> -->
  </div>
</template>

<script>
import getUser from "../composables/getUser";
// import { useRouter } from "vue-router";
import { typAsync } from "../composables/typAsync.js";
import {
  IonButton,
  // useIonRouter,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

//firebase imports
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

export default defineComponent({
  components: { IonButton },
  setup() {
    const { user } = getUser();
    // const router = useRouter();
    // const ionRouter = useIonRouter();
    const router = useRouter();
    const routeName = ref(useRoute().name);

    // const onPage = ref($route.path);

    function handleLogoutClick() {
      return typAsync(async () => {
        await signOut(auth);
        // handleButtonClick("login");
        // location.reload();
        location.replace("https://knockstoppers.com/login");
      });
    }

    function handleButtonClick(name) {
      router.push(`/${name}`);
    }

    return { handleLogoutClick, user, handleButtonClick, routeName };
  },
});
</script>

<style>
.button-nav-bar {
  /* --color: var(--ion-color-primary-contrast); */
  --color: gainsboro;
}
</style>
