import { Share } from "@capacitor/share";
// import { db } from "../firebase/config";
// import {
//   doc,
//   getDoc,

// } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { toastController } from "@ionic/vue";

export async function shareThis(title, text, url, dialogTitle) {
  const isMobile = Capacitor.isNativePlatform();

  if (isMobile) {
    await Share.share({
      title: title,
      text: text,
      url: url,
      dialogTitle: dialogTitle,
    });
  } else {
    if (navigator.share) {
      await navigator.share({
        title: title,
        text: text,
        url: url,
      });
    } else if (navigator.clipboard) {
      console.error("Web Share API not supported in this browser. ");

      await navigator.clipboard.writeText(url);
      await presentToast("Link copied to clipboard", 1500);
      // console.log("Link copied to clipboard");
    } else {
      await presentToast(`Copy Link: ${url}`, 5000);

      console.error(
        "Web Share API and Clipboard API not supported in this browser. "
      );
      return false;
    }
  }

  async function presentToast(text, duration) {
    const toast = await toastController.create({
      message: text,
      duration: duration,
      position: "bottom",
      // cssClass: "toast-message",
    });
    await toast.present();
  }

  return true;
}
