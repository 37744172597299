// view components
// import StarterPage from "./pages/StarterPage.vue";
// import LoginPage from "./pages/LoginPage.vue";
// import SignupPage from "./pages/SignupPage.vue";
// import PortalPage from "./pages/PortalPage.vue";
// import TabsPage from "../pages/TabsPage.vue";
// import CreateAddressesPage from "./pages/CreateAddressesPage.vue";
// import EmailVerification from "./pages/EmailVerification.vue";
// import EditAddressPage from "./pages/EditAddressPage.vue";
// import ReportContractorPage from "./pages/ReportContractorPage.vue";
// import ContractorPortalPage from "./pages/ContractorPortalPage.vue";
// import OwnerPage from "./pages/OwnerPage.vue";
// import ConditionsPage from "./pages/ConditionsPage.vue";
// import TermsPage from "./pages/TermsPage.vue";
// import PrivacyPage from "./pages/PrivacyPage.vue";
// import ContactPage from "./pages/ContactPage.vue";
// import FAQPage from "./pages/FAQPage.vue";
// import trialPage from "./pages/trialPage.vue";
// import BlogPost from "./pages/BlogPost.vue";
// import BlogDirectory from "./pages/BlogDirectory.vue";
const { slugs } = require("./slugs.js");

// to build sitemap in terminal: "npm run sitemap"

module.exports = {
  routes: [
    {
      path: "/",
      name: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/HomePage.vue"),
    },

    {
      path: "/qr",
      name: "qr",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/QRDummy.vue"),
    },
    {
      path: "/start",
      name: "start",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/StarterPage.vue"),
    },
    {
      path: "/access-denied",
      name: "access-denied",
      component: () =>
        import(/* webpackChunkName: "home" */ "./pages/AccessDenied.vue"),
    },

    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./pages/LoginPage.vue"),
    },

    {
      path: "/signup",
      name: "signup",
      component: () =>
        import(/* webpackChunkName: "signup" */ "./pages/SignupPage.vue"),
    },
    {
      path: "/contractors",
      name: "contractors",
      redirect: "/contractors/home",
    },
    {
      path: "/contractors/:postName",
      name: "contractors",
      component: () =>
        import(/* webpackChunkName: "contractors" */ "./pages/PortalPage.vue"),
      props: true,
      meta: {
        sitemap: {
          slugs: [...slugs.cities],
        },
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import(/* webpackChunkName: "terms" */ "./pages/TermsPage.vue"),
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () =>
        import(/* webpackChunkName: "NotFound" */ "./pages/NotFound.vue"),
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./pages/PrivacyPage.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./pages/ContactPage.vue"),
    },
    {
      path: "/FAQ",
      name: "FAQ",
      component: () =>
        import(/* webpackChunkName: "FAQ" */ "./pages/FAQPage.vue"),
    },
    {
      path: "/email-verification",
      name: "email-verification",
      component: () =>
        import(
          /* webpackChunkName: "email-verification" */ "./pages/EmailVerification.vue"
        ),
    },
    {
      path: "/directory",
      name: "directory",
      component: () =>
        import(/* webpackChunkName: "directory" */ "./pages/BlogDirectory.vue"),
    },
    {
      path: "/blog/:postName",
      name: "blog",
      component: () =>
        import(/* webpackChunkName: "blog" */ "./pages/BlogPost.vue"),
      props: true,
      meta: {
        sitemap: {
          slugs: [...slugs.blogIds],
        },
      },
    },
  ],
};
