<template>
  <modal-base>
    <template v-slot:modalContent>
      <ion-list>
        <ion-item color="light">
          <ion-label><h1>Create New Address</h1></ion-label>
        </ion-item>

        <autocomplete-search
          @search-location="updateAddress"
          :displayAddressButton="true"
          :displayCheckBox="true"
        ></autocomplete-search>
      </ion-list>
    </template>

    <template v-slot:submissionFooter>
      <ion-button v-if="buttonTruth" expand="block" @click="submitAddress()"
        >Submit</ion-button
      >
      <ion-button v-else :disabled="true" expand="block">Submit</ion-button>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import AutocompleteSearch from "../components/AutocompleteSearch.vue";
import { getGeoNeighbors } from "../composables/getGeoNeighbors.js";
import { typAsync } from "../composables/typAsync.js";
import { db } from "../firebase/config";
import {
  doc,
  updateDoc,
  deleteField,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  IonButton,
  modalController,
  IonItem,
  IonLabel,
  IonList,
  // IonCheckbox,
} from "@ionic/vue";
// import TermsOfService from "../components/TermsOfService.vue";

// import { IonContent } from "@ionic/vue";

import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  components: {
    ModalBase,
    AutocompleteSearch,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    // IonCheckbox,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  setup(props) {
    const addressValues = ref("");
    const userData = ref(props.userData);
    const buttonTruth = ref(false);

    watch(props, (newProps) => {
      userData.value = newProps.userData;
    });

    function updateAddress(value) {
      addressValues.value = value;
      buttonTruth.value = true;
      // console.log("addressValues: ", addressValues);
    }

    async function submitAddress() {
      return typAsync(async () => {
        let lat = addressValues.value.geodata.lat;
        let lng = addressValues.value.geodata.lng;
        let uid = userData.value.userId;

        if (userData.value.lat !== lat || userData.value.lng !== lng) {
          let currentDate = new Date();

          // get geohash
          let geohashes = await getGeoNeighbors(null, lat, lng, 4);

          let obj = {
            addressComponents: userData.value.addressComponents,
            formattedAddress: userData.value.formattedAddress,
            geodata: userData.value.geodata,
            geohashes: userData.value.geohashes,
            dateCreated: userData.value.addressDate,
            dateChanged: `${currentDate}`,
          };

          let oldAddresses = {
            ...userData.value.oldAddresses,
            [currentDate]: obj,
          };

          // console.log("update account");
          const docRef = doc(db, "accounts", uid);
          await updateDoc(docRef, {
            addressComponents: addressValues.value.addressComponents,
            formattedAddress: addressValues.value.formattedAddress,
            geodata: addressValues.value.geodata,
            geohashes: geohashes,
            addressDate: `${currentDate}`,
            oldAddresses: oldAddresses,
          });

          let oldGeohashes = userData.value.geohashes;
          for (let i = 0; i < oldGeohashes.length; i++) {
            // console.log("delete geohashMarkers", oldGeohashes[i], uid);
            let geohashRef = doc(db, "geohashMarkers", oldGeohashes[i]);
            await updateDoc(geohashRef, {
              [uid]: deleteField(),
            });
          }

          for (let i = 0; i < geohashes.length; i++) {
            // console.log("tried to upload geohash");

            let geohashRef = doc(db, "geohashMarkers", geohashes[i]);

            // console.log("get geohash doc: ", geohashes[i]);
            const docSnap = await getDoc(geohashRef);

            if (!docSnap.exists()) {
              await setDoc(geohashRef, {
                geohash: geohashes[i],
              });
            }

            // console.log("update geohash: ", geohashes[i]);
            await updateDoc(geohashRef, {
              [uid]: {
                houseNumber: addressValues.value.addressComponents.houseNumber,
                lat: lat,
                lng: lng,
              },
            });
          }
        }

        location.reload();
        modalController.dismiss(null, "cancel");
      });
    }

    return { updateAddress, addressValues, submitAddress, buttonTruth };
  },
});
</script>
